import { createStore } from "vuex";

export default createStore({
  state: {
    data: null,
    isLoggedIn: false,
    isRegIn: false,
    isTenMinutIn: false,
    isNoRegIn: false,
    regActive: false,
    logActive: false,
    isLogIn: false,
    isNoLogIn: false,
    isNoEnterPage: false,
    isHeader: true,
    user: null,
    userUid: null,
    mainDomen: 'https://api.examin.uz/'
  },
  getters: {
    getUser(state) {
      return state.user;
    },
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    IS_REG_IN_TRUE(state) {
      state.isRegIn = true;
    },
    IS_REG_IN_FALSE(state) {
      state.isRegIn = false;
    },
    IS_REG_MODAL_TRUE(state) {
      state.regActive = true;
    },
    IS_REG_MODAL_FALSE(state) {
      state.regActive = false;
    },
    IS_NO_REG_TRUE(state) {
      state.isNoRegIn = true;
    },
    IS_NO_REG_FALSE(state) {
      state.isNoRegIn = false;
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit("SET_USER", user);
    },
  },
  modules: {},
});
