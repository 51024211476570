<template>
  <div class="flex items-center justify-center min-h-screen bg-gray-100">
    <div class="max-w-md w-full">
      <h2 class="text-3xl text-center font-semibold mb-6">
        Boshqaruv paneliga kirish
      </h2>
      <form @submit.prevent>
        <div class="mb-4">
          <label for="number" class="block text-gray-700 font-medium mb-2"
            >Telefon raqamingizni kirgizing</label
          >
          <input
            id="number"
            type="number"
            class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            placeholder="Telefon raqamingizni kirgizing"
            v-model="phone"
          />

          <p v-if="!phoneIsvalid" class="text-red-600">To'ldirilishi shart</p>
          <p>
            <b class="text-red-600 me-2"
              >Telefon raqamni shu ko'rinishda yozing:</b
            ><span>999678641</span>
          </p>
        </div>
        <div class="mb-4 password-container">
          <label for="password" class="block text-gray-700 font-medium mb-2"
            >Parolingizni yozing</label
          >
          <input
            id="name"
            :type="showPassword ? 'text' : 'password'"
            class="w-full border border-gray-300 rounded py-2 px-4"
            placeholder="Parolingizni yozing"
            v-model="data.password"
          />
          <span class="show-password" @click="togglePasswordVisibility">
            {{ showPassword ? "Yashirish" : "Ko'rish" }}
          </span>
        </div>
        <p class="text-red-600">{{ errorMessage }}</p>
        <button
          type="submit"
          class="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md"
          @click="login"
        >
          Kirish
        </button>
      </form>
    </div>
  </div>
</template>

<script>
let url = "https://api.onlayn-test.uz/";
export default {
  name: "Login",
  data() {
    return {
      phone: "",
      data: {
        phone: "998",
        password: "",
      },
      errorMessage: "",
      showPassword: false,
    };
  },
  computed: {
    phoneIsvalid() {
      return !!this.phone;
    },
    passwordIsvalid() {
      return !!this.data.password;
    },
    formIsvalid() {
      return this.phoneIsvalid && this.passwordIsvalid;
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    login() {
      this.data.phone += this.phone;
      if (this.formIsvalid) {
        this.axios
          .post(url + "api/user/login", this.data)
          .then((res) => {
            console.log(res);
            if (res.data.status == false) {
              this.errorMessage = res.data.message;
            } else {
              let token = res.data.data;
              console.log(token);
              localStorage.setItem("token", token);
              this.axios
                .get(url + "api/user/decode", {
                  headers: {
                    Authorization: token,
                  },
                })
                .then((res) => {
                  console.log(res);
                  let id = res.data.decodeToken.id;
                  let role = res.data.decodeToken.role;
                  localStorage.setItem("userid", id);
                  localStorage.setItem("role", role);

                  if (role == "hamkor") {
                    this.$router.push({
                      name: "linkprofile",
                    });
                  } else if (role == "admin" || role == "moderator") {
                    this.$router.push({
                      name: "home",
                    });
                  } else if (role == "user") {
                    localStorage.removeItem("token");
                    this.$router.push({ name: "login" });
                  }
                  this.userId();
                })
                .catch((err) => {
                  console.log(err);
                });
              // this.$router.push({
              //   name: "home",
              // });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        console.log("Tizimga kira olmadingiz !");
      }
    },
    userId() {
      const id = localStorage.getItem("userid");
      this.axios
        .get(url + "api/user/" + id)
        .then((res) => {
          console.log(res.data.data.hamkorlink);
          localStorage.setItem("link", res.data.data.hamkorlink);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
/* Optional styles specific to this component */
.password-container {
  position: relative;
}

.show-password {
  position: absolute;
  right: 10px;
  top: 69%;
  transform: translateY(-50%);
  cursor: pointer;
  color: blue;
  font-size: 15px;
}
</style>
