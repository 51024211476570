<template>
  <div
    class="fixed top-0 right-0 z-50 w-fit p-4 rounded-[5px] bg-green-600 text-white font-bold"
  >
    <span>Siz ro'yhatdan o'tdingiz!</span>
  </div>
</template>

<script>
export default {}
</script>
