<template>
  <div>
    <div
      class="full_cover"
      :class="{ full_cover_active: active }"
      @click="active = !active"
    ></div>
    <div class="header">
      <nav class="nav_header">
        <div class="logo_s">
          <img
            src="@/assets/img/logo-light.png"
            class="navbar-brand-img"
            alt="logo_site"
          />
        </div>
        <ul
          class="nav_link"
          :class="{ header_active: active }"
          v-if="user.role != 'hamkor'"
        >
          <li class="nav_item">
            <router-link to="/"> Bosh sahifa</router-link>
          </li>
          <li class="nav_item">
            <router-link to="/testlar">Testlar</router-link>
          </li>
          <li
            v-if="user.role != 'moderator' && user.role != 'user'"
            class="nav_item"
          >
            <router-link to="/pupils">O'quvchilar</router-link>
          </li>
          <!-- <li v-else class="nav_item">
            <router-link to="/pupils">O'quvchilar</router-link>
          </li> -->
          <li
            class="nav_item"
            v-if="user.role != 'moderator' && user.role != 'user'"
          >
            <a href="/konkursresult">Konkurs natijalari</a>
          </li>
          <li
            class="nav_item"
            v-if="user.role != 'moderator' && user.role != 'user'"
          >
            <router-link to="/winnertitle">G'oliblar</router-link>
          </li>
          <li
            class="nav_item"
            v-if="user.role != 'moderator' && user.role != 'user'"
          >
            <router-link to="/team">Jamoa</router-link>
          </li>
          <li
            class="nav_item"
            v-if="user.role != 'moderator' && user.role != 'user'"
          >
            <router-link to="/news">Yangiliklar</router-link>
          </li>
        </ul>
        <div class="header_btn">
          <!-- <div class="btn_login bg-orange-500">
            <span>Kirish</span>
            <span>Kirish</span>
          </div> -->
          <div
            class="btn_register bg-orange-500"
            @click.prevent="logout"
          >
            <span>Chiqish</span>
          </div>
          <div
            class="text-center text-yellow-600 font-bold"
            style="margin-left: 5px"
          >
            <h4>{{ user.role }}</h4>
            <h4>{{ user.name }}</h4>
          </div>
        </div>
      </nav>
      <div class="burger" @click="active = !active">
        <span class="line1"></span>
        <span class="line2"></span>
        <span class="line3"></span>
      </div>
    </div>
  </div>
</template>

<script>
let url = 'https://api.examin.uz/';
export default {
  data() {
    return {
      home: 'Bosh sahifa',
      active: false,
      scrollActive: false,
      decodeToken: {
        id: '',
        role: '',
      },
      user: '',
    };
  },
  created() {
    const token = localStorage.getItem('token');
    this.axios
      .get(url + 'api/user/decode', {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        let result = res.data;
        // console.log(result.decodeToken);
        this.decodeToken.id = result.decodeToken.id;
        this.decodeToken.role = result.decodeToken.role;

        this.axios
          .get(url + 'api/user/' + this.decodeToken.id)
          .then((res) => {
            let result = res.data.data;
            this.user = result;
            if (result.role == 'user') {
              localStorage.removeItem('token');
              this.$router.push({ name: 'login' });
            }

            this.$store.commit('SET_USER', this.user.role);
            this.$store.dispatch('setUser', this.user.role);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('userid');
      localStorage.removeItem('role');
      localStorage.removeItem('link');
      this.$router.push({ name: 'login' });
    },
  },
  mounted() {
    window.addEventListener('scroll', function () {
      let fixed = document.querySelector('.header');
      let btnLogin = document.querySelector('.btn_login');
      let btn_register = document.querySelector('.btn_register');

      fixed.classList.toggle('bg-blue-900', window.scrollY > 50);
      // btnLogin.classList.toggle("btn_login_reg_bg", window.scrollY > 50);
      btn_register.classList.toggle(
        'btn_login_reg_bg',
        window.scrollY > 50
      );
    });
  },
};
</script>

<style scoped>
.full_cover {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  background: #000;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: rgba(255, 255, 255, 0.6);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 75px;
  position: -webkit-sticky;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.nav_header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  margin: 0 auto;
  height: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 102;
}

.nav_header .logo_s {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 10rem;
  flex: 1 1 7rem;
}

.nav_header .nav_link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-flex: 3;
  -ms-flex: 3 1 20rem;
  flex: 3 1 20rem;
  margin: 0;
  padding: 0;
}

.nav_header .nav_link a {
  color: #fff;
  font-size: 18px;
}

.nav_header .header_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 10rem;
  flex: 1 1 7rem;
}

.nav_header .header_btn .btn_login {
  width: 100px;
  height: 40px;
  cursor: pointer;
  /* background-color: rgba(255, 255, 255, 0.1); */
  border-radius: 10px;
  margin-right: 15px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.nav_header .header_btn .btn_register {
  width: 140px;
  height: 40px;
  cursor: pointer;
  /* background-color: rgba(255, 255, 255, 0.1); */
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.nav_header .header_btn .user_name_icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.nav_header .header_btn .user_name_icon i {
  color: #fff;
}

.nav_header .header_btn .user_name_icon h4 {
  margin: 0;
  color: #fff;
}

.nav_header .header_btn .user_name_icon .fa-user-circle {
  font-size: 27px;
  margin-right: 10px;
}

.nav_header .header_btn .user_name_icon .fa-chevron-down {
  font-size: 15px;
  margin-left: 5px;
}

.nav_header .header_btn .user_name_icon .drop_down {
  padding: 0 3px;
  display: none;
  width: 200px;
  position: absolute;
  z-index: 4;
  border: 1px solid;
  border-radius: 10px;
  bottom: -129px;
  left: 0;
  background-color: #fff;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.nav_header .header_btn .user_name_icon .drop_down li {
  padding: 5px 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #2f2d51;
}

.nav_header .header_btn .user_name_icon .drop_down li i {
  font-size: 20px;
  color: #000;
  margin-right: 10px;
}

.burger {
  display: none;
  width: 30px;
  height: fit-content;
  transform: translate(-24px, 24px);
  z-index: 10;
  cursor: pointer;
}

.burger span {
  display: block;
  margin: 4px 0;
  height: 4px;
  width: 100%;
  background-color: #fff;
  border-radius: 3px;
}

.btn_login_reg_bg {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

@media (max-width: 1000px) {
  .nav_link {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 103;
    width: 80%;
    height: 100%;
    background-color: #fff;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
  .nav_link a {
    color: #2f2d51 !important;
  }
  .header_btn {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-right: 40px;
    margin-right: 28px;
  }
  .burger {
    display: block;
  }
}

@media (max-width: 550px) {
  .navbar-brand-img {
    height: 2.1rem !important;
  }
  .btn_login {
    margin-right: 10px !important;
    width: 65px !important;
    height: 35px !important;
    font-size: 13px;
  }
  .btn_register {
    width: 120px !important;
    height: 35px !important;
    font-size: 13px;
  }
  .burger {
    margin-top: 24px;
    width: 25px;
    right: 20px;
  }
  .burger span {
    height: 3px;
  }
  .header_btn {
    margin-right: 28px;
  }
}

@media (max-width: 425px) {
  .navbar-brand-img {
    height: 2.1rem !important;
  }
  .btn_login {
    margin-right: 10px !important;
    width: 65px !important;
    height: 35px !important;
    font-size: 13px;
  }
  .btn_register {
    width: 120px !important;
    height: 35px !important;
    font-size: 13px;
  }
  .burger {
    margin-top: 24px;
    width: 25px;
    right: 20px;
  }
  .burger span {
    height: 3px;
  }
  .header_btn {
    margin-right: 28px;
  }
}

@media (max-width: 375px) {
  .header {
    height: 70px;
  }
  .navbar-brand-img {
    height: 2.1rem !important;
  }
  .btn_login {
    width: 47px !important;
    height: 30px !important;
    font-size: 11px;
  }
  .btn_register {
    width: 99px !important;
    height: 30px !important;
    font-size: 11px;
  }
  .header_btn {
    margin-right: 28px;
  }
  .burger {
    margin-top: 24px;
    width: 23px;
  }
  .burger span {
    margin: 3px 0;
  }
}

@media (max-width: 325px) {
  .header {
    height: 70px;
  }
  .navbar-brand-img {
    height: 2.1rem !important;
  }
  .btn_login {
    width: 47px !important;
    height: 30px !important;
    font-size: 13px;
  }
  .btn_register {
    width: 99px !important;
    height: 30px !important;
    font-size: 13px;
  }
  .header_btn {
    margin-right: 28px;
  }
  .burger {
    margin-top: 24px;
    width: 23px;
  }
  .burger span {
    margin: 3px 0;
  }
}

.drop_active {
  display: block !important;
}

.header_active {
  left: 0;
}

.full_cover_active {
  display: block;
}
</style>
