<template>
  <div>
    <Header />
    <SuccessReg v-if="$store.state.isRegIn" />
    <NoSuccessReg v-if="$store.state.isNoRegIn" />
    <router-view />
  </div>
</template>

<script>
import SuccessReg from "../components/Login/SuccessReg.vue";
import NoSuccessReg from "../components/Login/NoSuccessReg.vue";
import Header from "../components/Header.vue";
export default {
  components: {
    Header,
    NoSuccessReg,
    SuccessReg,
  },
};
</script>
