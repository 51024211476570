<template>
  <div class="flex items-center justify-center min-h-screen bg-gray-100">
    <div class="max-w-md text-center">
      <h2 class="text-6xl font-semibold text-red-500 mb-6">404</h2>
      <p class="text-gray-700 text-xl mb-6">OOO bunday sahifa topilmadi</p>
      <router-link
        to="/"
        class="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md"
        >Bosh sahifaga qaytish</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style>
/* Optional styles specific to this component */
</style>
